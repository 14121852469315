$spacer: 1rem;

// @media (min-width: 576px) {
    .mt-1 {
        margin-top: $spacer * .6 !important;
    }

    .mt-2 {
        margin-top: $spacer * 1.2 !important;
    }

    .mt-3 {
        margin-top: $spacer * 1.8 !important;
    }

    .mt-4 {
        margin-top: $spacer * 2.4 !important;
    }

    .mt-5 {
        margin-top: $spacer * 3 !important;
    }

    .mt-10 {
        margin-top: $spacer * 6 !important;
    }

    // Margin bottom

    .mb-0 {
        margin-bottom: $spacer * 0 !important;
    }

    .mb-1 {
        margin-bottom: $spacer * .6 !important;
    }

    .mb-2 {
        margin-bottom: $spacer * 1.2 !important;
    }

    .mb-5 {
        margin-bottom: $spacer * 3 !important;
    }

    .mb-10 {
        margin-bottom: $spacer * 6 !important;
    }
// }