/*
Template Name: Wrakit UI Kit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import "variable";

@import "animate.css";

@import "app";
@import "common";
@import "extra";
@import "authentication";
@import "responsive";

@import "icons/font-awesome/css/font-awesome.min.css";
@import "icons/simple-line-icons/css/simple-line-icons.css";
@import "icons/themify-icons/themify-icons.css";
@import "angular-notifier/styles.css";

@import "helpers";

p {
    padding: 0;
    margin: 0;
}
